<template>
  <!-- 支持渲染多级 menu 菜单 -->
  <el-sub-menu v-if="route.children.length > 0" :index="route.path">
    <template #title>
      <menu-item :title="route.meta.title" :icon="route.meta.icon"></menu-item>
    </template>
    <!-- 循环渲染 -->
    <sidebar-item
      v-for="item in route.children"
      :key="item.path"
      :route="item"
    ></sidebar-item>
  </el-sub-menu>
  <!-- 渲染 item 项 -->
  <el-menu-item v-else :index="route.path">
    <menu-item :title="route.meta.title" :icon="icon"></menu-item>
  </el-menu-item>
</template>

<script setup>
import MenuItem from './MenuItem';
import { defineProps, computed } from 'vue';
import { useRouter } from 'vue-router';

// 定义 props
const props = defineProps({
  route: {
    type: Object,
    required: true
  }
  // activeMenu: {
  //   type: String,
  //   required: true
  // }
});
const router = useRouter();

const icon = computed(() => {
  if (router.currentRoute.value.path === props.route.path) {
    return props.route.meta.activeIcon;
  }
  return props.route.meta.icon;
});
</script>

<style lang="scss" scoped></style>
