<template>
  <div class="sidebar">
    <div
      class="logo-container"
      :class="{ sidebarOpened: $store.getters.sidebarOpened }"
    >
      <img src="../../../assets/xiaolanzhinong.jpg" width="176" />
      <!-- <el-avatar
        :size="logoHeight"
        shape="square"
        src="https://m.imooc.com/static/wap/static/common/img/logo-small@2x.png"
      /> -->
      <!-- <h1 class="logo-title" v-if="$store.getters.sidebarOpened">小蓝智农</h1> -->
    </div>
    <el-scrollbar>
      <sidebar-menu></sidebar-menu>
    </el-scrollbar>
  </div>
</template>

<script setup>
import SidebarMenu from './SidebarMenu';

const logoHeight = 44;
</script>

<style lang="scss" scoped>
@import '@/styles/variables.module.scss';
.logo-container {
  height: v-bind(logoHeight) + 'px';
  padding: 10px 0 22px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: padding-left #{$sideBarDuration};
  padding-left: 0;
  &.sidebarOpened {
    // padding-left: 20px;
    padding-left: 17px;
  }
  .logo-title {
    margin-left: 10px;
    color: #fff;
    font-weight: 600;
    line-height: v-bind(logoHeight) + 'px';
    font-size: 16px;
    white-space: nowrap;
  }
}
</style>
