<template>
  <div class="navbar">
    <hamburger class="hamburger-container" />
    <breadcrumb id="guide-breadcrumb" class="breadcrumb-container" />

    <div class="right-menu">
      <!-- <guide class="right-menu-item hover-effect" /> -->
      <!-- <header-search class="right-menu-item hover-effect"></header-search> -->
      <!-- <screenfull class="right-menu-item hover-effect" /> -->
      <!-- <theme-picker class="right-menu-item hover-effect"></theme-picker> -->
      <!-- <lang-select class="right-menu-item hover-effect" /> -->
      <!-- 头像 -->
      <el-dropdown class="avatar-container" trigger="click">
        <div class="avatar-wrapper">
          <el-avatar
            shape="square"
            :size="40"
            :src="avatarImgUrl"
            fit="contain"
          ></el-avatar>
          <!-- https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png -->
          <!-- <i class="el-icon-s-tools"></i> -->
        </div>
        <template #dropdown>
          <el-dropdown-menu class="user-dropdown">
            <router-link to="/">
              <el-dropdown-item> {{ $t('msg.navBar.home') }} </el-dropdown-item>
            </router-link>
            <!-- <a target="_blank" href="">
              <el-dropdown-item>{{ $t("msg.navBar.course") }}</el-dropdown-item>
            </a> -->
            <el-dropdown-item divided @click="logout">
              {{ $t('msg.navBar.logout') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script setup>
import Hamburger from '@/components/Hamburger';
import breadcrumb from '@/components/Breadcrumb';
// import LangSelect from "@/components/LangSelect";
// import ThemePicker from '@/components/ThemeSelect/index';
// import Screenfull from '@/components/Screenfull';
// import HeaderSearch from '@/components/headerSearch';
// import Guide from '@/components/Guide';

import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
const store = useStore();
const logout = () => {
  store.dispatch('user/logout');
};
// /profile/upload/2023/05/26/9155851d-ac6c-461e-b350-64f5641a6993.jpg
const avatar = ref('');

const baseURL = process.env.VUE_APP_BASE_API;
const avatarImgUrl = computed(() => {
  if (!avatar.value) return '';
  const regex = /^http/;
  if (regex.test(avatar.value)) return avatar.value;
  return baseURL + avatar.value;
});

onMounted(() => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  console.log('userInfo', userInfo);
  avatar.value = userInfo.avatar;
});
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 50px;
    height: 100%;
    float: left;
    // 暂时把 关闭打开 隐藏掉
    // cursor: pointer;
    // // hover 动画
    // transition: background 0.5s;

    // &:hover {
    //   background: rgba(0, 0, 0, 0.1);
    // }
  }
  .breadcrumb-container {
    float: left;
  }
  .right-menu {
    display: flex;
    align-items: center;
    float: right;
    padding-right: 16px;

    :deep(.right-menu-item) {
      display: inline-block;
      padding: 0 18px 0 0;
      font-size: 24px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
      }
    }

    :deep(.avatar-container) {
      cursor: pointer;
      .avatar-wrapper {
        // background-color: aquamarine;
        margin-top: 5px;
        position: relative;
        .el-avatar {
          --el-avatar-background-color: none;
          margin-right: 12px;
        }
      }
    }
  }
}
</style>
