<template>
  <div class="app-main">
    <router-view v-slot="{ Component, route }" :key="$route.fullPath">
      <transition name="fade-transform" mode="out-in">
        <keep-alive>
          <component :is="Component" :key="route.name" />
        </keep-alive>
      </transition>
    </router-view>
  </div>
</template>

<script setup>
import { watch } from 'vue';
import { isTags } from '@/utils/tags';
import { generateTitle, watchSwitchLang } from '@/utils/i18n';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const route = useRoute();

/**
 * 生成 title
 */
const getTitle = (route) => {
  let title = '';
  if (!route.meta) {
    // 处理无 meta 的路由
    const pathArr = route.path.split('/');
    title = pathArr[pathArr.length - 1];
    console.log(title);
  } else {
    title = generateTitle(route.meta.title);
  }
  return title;
};

/**
 * 监听路由变化
 */
const store = useStore();
watch(
  route,
  (to, from) => {
    if (!isTags(to.path)) return;
    const { fullPath, meta, name, params, path, query } = to;
    store.commit('app/addTagsViewList', {
      fullPath,
      meta,
      name,
      params,
      path,
      query,
      title: getTitle(to)
    });
  },
  {
    immediate: true
  }
);

/**
 * 国际化 tags
 */
watchSwitchLang(() => {
  store.getters.tagsViewList.forEach((route, index) => {
    console.log(route);
    store.commit('app/changeTagsView', {
      index,
      tag: {
        ...route,
        title: getTitle(route)
      }
    });
  });
});
</script>

<style lang="scss" scoped>
.app-main {
  background-color: #fafafa;
  min-height: calc(100vh);
  width: 100%;
  position: relative;
  overflow: hidden;
  // padding: 88px 0px 20px 0px;
  padding-top: 88px;
  box-sizing: border-box;
}
</style>
