<template>
  <!-- 一级 menu 菜单 -->
  <el-menu
    :default-active="activeMenu"
    :background-color="$store.getters.cssVar.menuBg"
    :unique-opened="true"
    :text-color="$store.getters.cssVar.menuText"
    :active-text-color="$store.getters.cssVar.menuActiveText"
    router
    :collapse="!$store.getters.sidebarOpened"
  >
    <!-- 子集 menu 菜单 -->
    <sidebar-item
      v-for="item in routes"
      :key="item.path"
      :route="item"
    ></sidebar-item>
    <!-- :active-menu="activeMenu" -->
  </el-menu>
</template>

<script setup>
import { computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { filterRouters, generateMenus } from '@/utils/route';
import SidebarItem from './SidebarItem';

const router = useRouter();
const routes = computed(() => {
  const filterRoutes = filterRouters(router.getRoutes());
  // let meta = {
  //   meta: {
  //     title: "collectorUnitDetail",
  //     icon: "personnel-manage",
  //   },
  // };
  // let meta1 = {
  //   meta: {
  //     title: "toCollectorDetail",
  //     icon: "personnel-manage",
  //   },
  // };
  // Object.assign(filterRoutes[3].children[2], meta);
  // Object.assign(filterRoutes[3].children[3], meta1);
  return generateMenus(filterRoutes);
});

// 计算高亮 menu 的方法
const route = useRoute();
const activeMenu = computed(() => {
  const { path } = route;
  return path;
});
// console.log('现在的activeMenu', activeMenu);
</script>
