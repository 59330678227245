import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// 导入element
import 'element-plus/dist/index.css';
import ElementPlus from 'element-plus';

// 导入i18n实例
import i18n from './i18n';
// 插件
import installElementPlus from './plugins/element';
// 初始化样式表
import '@/styles/index.scss';
// 导入 svgIcon
import installIcons from '@/icons';
// 导入api接口
import * as api from './api';
// 导入权限控制模块
import './permission';
// 树形表格
import 'xe-utils';
import VXETable from 'vxe-table';
import 'vxe-table/lib/style.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';

const app = createApp(App);
installElementPlus(app);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
installIcons(app);
function useTable(app) {
  app.use(VXETable);
}
// 全局挂载配置
app.config.globalProperties.$api = api;
// 注册
app
  .use(store)
  .use(router)
  .use(useTable)
  .use(i18n)
  .use(ElementPlus)
  .mount('#app');
