import axios from 'axios';
import { ElMessage } from 'element-plus';
import { getItem, removeAllItem } from '@/utils/storage';
import router from '@/router';
import { setTimeout } from 'core-js';
// 创建axios实例对象，添加全局配置
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 3000
});

// TODO:做提示和跳转
// const TOKEN_INVALID = 'Token认证失败,请重新登录';
// const NETWORK_ERROR = '网络请求异常,请稍后重试';

// 请求拦截器
service.interceptors.request.use((req) => {
  const token = getItem('token') || '';
  if (token) req.headers['X-YAuth-Token'] = token;
  return req;
});

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    if (res.data.code === 401) {
      // this.commit("user/setToken", "");
      // this.commit("user/setUserInfo", {});
      // setItem("token", {});
      // setItem("userInfo", {});
      removeAllItem();
      router.push({ path: '/login' });

      ElMessage.error(res.data.msg);
      setTimeout(() => {
        if (window.location.href.indexOf('#reloaded') == -1) {
          window.location.href = window.location.href + '#reloaded';
          window.location.reload();
        }
      }, 1000);
    } else {
      const { data } = res;
      return data;
    }
  },

  (error) => {
    console.log('errpr', error);
    ElMessage.error(error.message);
    return Promise.reject(error);
  }
);

export default service;
