import { createRouter, createWebHashHistory } from 'vue-router';
import layout from '@/layout/index';
/**
 * 私有路由表
 */
const privateRoutes = [
  // 设备管理
  {
    path: '/equipment',
    component: layout,
    redirect: '/equipment/equipmentList',
    meta: {
      title: 'equipment',
      icon: 'equipment-manager',
      activeIcon: 'equipment-manager-active'
    },
    // 二级菜单
    children: [
      {
        path: '/equipment/equipmentList',
        component: () => import('@/views/equipment'),
        meta: {
          title: 'equipmentList',
          icon: 'equipment-list',
          activeIcon: 'equipment-list-active'
        }
      },
      {
        path: '/equipment/equipmentDetail',
        component: () => import('@/views/equipment/equipmentDetail'),
        meta: {
          title: 'equipmentDetail',
          icon: 'equipment-detail',
          activeIcon: 'equipment-detail-active'
        }
      },
      {
        path: '/equipment/collectorDetail',
        component: () => import('@/views/equipment/collectorDetail'),
        meta: {
          title: 'collectorDetail',
          icon: 'collector-detail',
          activeIcon: 'collector-detail-active'
        }
      },
      {
        path: '/equipment/collectorUnitDetail',
        component: () => import('@/views/equipment/collectorUnitDetail'),
        meta: {
          title: 'collectorUnitDetail',
          icon: 'collector-unit-detail',
          activeIcon: 'collector-unit-detail-active'
        }
      }
    ]
  }
  // ,
  // // 农户作业管理
  // {
  //   path: '/agriculture',
  //   component: layout,
  //   redirect: '/agriculture/farmerList',
  //   meta: {
  //     title: 'agriculture',
  //     icon: 'agriculture',
  //     activeIcon: 'equipment-manager-active'
  //   },
  //   // 二级菜单
  //   children: [
  //     {
  //       path: '/agriculture/farmerList',
  //       component: () => import('@/views/agriculture/farmerList'),
  //       meta: {
  //         title: 'farmerList',
  //         icon: 'farmer',
  //         activeIcon: 'farmer-active'
  //       }
  //     },
  //     {
  //       path: '/agriculture/farmerDetail',
  //       component: () => import('@/views/agriculture/farmerDetail'),
  //       meta: {
  //         title: 'farmerDetail',
  //         icon: 'farmer',
  //         activeIcon: 'farmer-active'
  //       }
  //     },
  //     {
  //       path: '/agriculture/pondList',
  //       component: () => import('@/views/agriculture/pondList'),
  //       meta: {
  //         title: 'pondList',
  //         icon: 'farmer',
  //         activeIcon: 'farmer-active'
  //       }
  //     },
  //     {
  //       path: '/agriculture/pondWork',
  //       component: () => import('@/views/agriculture/pondWork'),
  //       meta: {
  //         title: 'pondWork',
  //         icon: 'farmer',
  //         activeIcon: 'farmer-active'
  //       }
  //     }
  //   ]
  // }
];
// 公开路由表
const publicRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login/index')
  },
  {
    path: '/',
    component: layout,
    redirect: '/profile',
    children: [
      {
        path: '/profile',
        name: 'profile',
        component: () => import('@/views/profile/index'),
        meta: {
          title: 'profile',
          icon: 'profile',
          activeIcon: 'profile-active'
        }
      }
    ]
  }
];
const router = createRouter({
  history: createWebHashHistory(),
  routes: [...publicRoutes, ...privateRoutes]
});

export default router;
