<template>
  <div class="tags-view-container">
    <router-link
      class="tags-view-item"
      :class="isActive(tag) ? 'active' : ''"
      :style="{
        backgroundColor: isActive(tag) ? 'rgb(64, 158, 255)' : '',
        borderColor: isActive(tag) ? 'rgb(64, 158, 255)' : ''
      }"
      v-for="(tag, index) in $store.getters.tagsViewList"
      :key="tag.fullPath"
      :to="{ path: tag.fullPath }"
      @contextmenu.prevent="openMenu($event, index)"
    >
      {{ tag.title }}
      <!-- v-show="!isActive(tag)" -->
      <!-- <el-icon
        class="el-icon-close"
        name="el-icon-close"
        color="white"
        @click.prevent.stop="onCloseClick(index)"
      ></el-icon> -->
      <el-icon
        v-show="!isHome(tag)"
        class="el-icon-close"
        @click.prevent.stop="onCloseClick(index, tag)"
        ><Close
      /></el-icon>
      <!-- <i class="el-icon-close" @click.prevent.stop="onCloseClick(index)" /> -->
    </router-link>
    <context-menu
      v-show="visible"
      :style="menuStyle"
      :index="selectIndex"
    ></context-menu>
  </div>
</template>

<script setup>
import ContextMenu from './ContextMenu.vue';
import { ref, reactive, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ElIcon } from 'element-plus';
const route = useRoute();
const router = useRouter();

// console.log("[Log] tags-view-item-->", $store.getters.tagsViewList);

/**
 * 是否是首页标签
 */
const isHome = (tag) => {
  // console.log(tag);
  return tag.path === '/profile';
};

/**
 * 是否被选中
 */
const isActive = (tag) => {
  return tag.path === route.path;
};

/**
 * 关闭 tag 的点击事件
 */
const store = useStore();
const onCloseClick = (index, tag) => {
  store.commit('app/removeTagsView', {
    type: 'index',
    index
  });
  // 查询一下 index对应的 tagsView 有没有,没有的话 拿 index-1 的
  // 跳转到对应的tags上去
  if (isActive(tag)) {
    const tagsViewList = store.getters.tagsViewList;
    let goIndex = index;
    if (tagsViewList.length <= goIndex) {
      goIndex--;
    }
    router.push(tagsViewList[goIndex].path);
  }
};

// contextMenu 相关
const selectIndex = ref(0);
const visible = ref(false);
const menuStyle = reactive({
  left: 0,
  top: 0
});
/**
 * 展示 menu
 */
const openMenu = (e, index) => {
  const { x, y } = e;
  menuStyle.left = x + 'px';
  menuStyle.top = y + 'px';
  selectIndex.value = index;
  visible.value = true;
};
const closeMenu = () => {
  visible.value = false;
};
watch(visible, (val) => {
  if (val) {
    document.body.addEventListener('click', closeMenu);
  } else {
    document.body.removeEventListener('click', closeMenu);
  }
});
</script>

<style lang="scss" scoped>
.tags-view-container {
  height: 34px;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #d8dce5;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);
  .tags-view-item {
    display: inline-block;
    position: relative;
    cursor: pointer;
    height: 26px;
    line-height: 26px;
    border: 1px solid #d8dce5;
    color: #495060;
    background: #fff;
    padding: 0 8px;
    font-size: 12px;
    margin-left: 5px;
    margin-top: 4px;
    &:first-of-type {
      margin-left: 15px;
    }
    &:last-of-type {
      margin-right: 15px;
    }
    &.active {
      color: #fff;
      &::before {
        content: '';
        background: #fff;
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        position: relative;
        margin-right: 4px;
      }
    }
    // close 按钮
    .el-icon-close {
      display: inline-block;
      // width: 16px;
      // height: 16px;
      // line-height: 26px;
      margin-left: 5px;
      vertical-align: -2px;
      border-radius: 50%;
      text-align: center;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transform-origin: 100% 50%;
      &:before {
        transform: scale(0.6);
        display: inline-block;
        vertical-align: -3px;
      }
      &:hover {
        background-color: #b4bccc;
        color: #fff;
      }
    }
  }
}
</style>
