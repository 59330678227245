import request from '@/utils/request';

/**
 * 登录
 */
export const login = (data) => {
  return request({
    url: '/api/login/register',
    method: 'post',
    data
  });
};

/**
 * 发送手机号二维吗
 */
export const getCode = (data) => {
  return request({
    url: '/api/login/smsCode',
    method: 'get',
    params: data
  });
};

/**
 * 获取二维码code
 */
export const getQrCode = () => {
  return request({
    url: '/api/login/getQrCode',
    method: 'get'
    // params: data,
  });
};

/**
 * 轮询二维码状态
 */
export const getQrCodeStatus = (data) => {
  return request({
    url: '/api/login/getQrCodeStatus',
    method: 'get',
    params: data
  });
};
