// import { createStore } from 'vuex';

// const store = createStore({
//   state() {
//     return {
//       data: ''
//     };
//   },
//   mutations: {
//     setData(state, payload) {
//       state.data = payload;
//     }
//   }
// });

// export default store;

export default {
  namespaced: true,
  state: () => ({
    data: ''
  }),
  mutations: {
    /**
     * 设置主题色
     */
    setData(state, payload) {
      state.data = payload;
    }
  }
};
